<template>
    <div>
        <b-card-code>
            <b-tabs>
                <b-tab title="Informacion Productor">
                    <b-row>
                        <b-col md="6" xl="6" class="mb-1">
                            <b-form-group label="Nombre(s)" label-for="basicInput">
                            <b-form-input v-model="profileDetails.firstName" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>                
                        <b-col md="6" xl="6" class="mb-1">
                            <b-form-group label="Apellido(s)" label-for="basicInput">
                            <b-form-input v-model="profileDetails.lastName" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>  
                    </b-row>
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Tipo de Documento" label-for="basicInput">                        
                            <b-form-select v-model="profileDetails.documentType" :options="documentTypeOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Numero de Documento" label-for="basicInput">
                            <b-form-input v-model="profileDetails.documentNumber" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>  
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Copia Documento" label-for="basicInput">                        
                                <b-form-file placeholder="Seleccione un documento" v-model="documentFile" />
                                <b-card-text @click="openDocument(profileDetails.documentUrl)" v-if="profileDetails.documentUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Genero" label-for="basicInput">
                            <b-form-select v-model="profileDetails.gender" :options="genderOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col> 
                    </b-row>
                    <b-row>
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Email" label-for="basicInput">
                                <b-form-input v-model="profileDetails.contactEmail" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Celular" label-for="basicInput">
                                <b-form-input v-model="profileDetails.contactCellphone" />
                            </b-form-group>
                        </b-col>
                        <b-col md="4" xl="4" class="mb-1">
                            <b-form-group label="Telefono Fijo" label-for="basicInput">
                                <b-form-input v-model="profileDetails.contactPhone" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Direccion" label-for="basicInput">
                                <b-form-input v-model="profileDetails.address" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Departamento" label-for="basicInput">                        
                                <b-form-select @change="onDepartamentoSelect" v-model="profileDetails.state" :options="departamentosList" value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>   
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                                <b-form-select v-model="profileDetails.city" :options="municipiosList" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                        <label for="example-datepicker">Fecha Nacimiento</label>
                            <b-form-datepicker show-decade-nav="true" v-model="profileDetails.birthDate" class="mb-1"/>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12" xl="12" class="mb-1">
                            <label for="example-datepicker">Description</label>
                            <b-form-textarea v-model="profileDetails.description" id="textarea-default" placeholder="Digite descripción del productor" rows="3"/>
                        </b-col>   
                    </b-row>
                </b-tab>

                <b-tab title="Informacion Bancaria">
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Numero de Cuenta" label-for="basicInput">
                            <b-form-input v-model="profileDetails.bankAccountNumber" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>  
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Tipo" label-for="basicInput">
                            <b-form-select v-model="profileDetails.bankAccountType" :options="accountTypeOptions" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Banco" label-for="basicInput">
                            <b-form-input v-model="profileDetails.bankAccountBank" placeholder="Digite"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab title="Documentos">
                    <b-row>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="Certificación Bancaria" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="bankAccountCertificationFile" />
                                <b-card-text @click="openDocument(profileDetails.bankAccountCertificationUrl)" v-if="profileDetails.bankAccountCertificationUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                        <b-col md="3" xl="3" class="mb-1">
                            <b-form-group label="RUT" label-for="basicInput">
                                <b-form-file placeholder="Seleccione un documento" v-model="rutFile" />
                                <b-card-text @click="openDocument(profileDetails.rutUrl)" v-if="profileDetails.rutUrl" class="cursor-pointer sosty-font-smaller">abrir/descargar <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                            </b-form-group>
                        </b-col>
                    </b-row>        
                </b-tab>
                
            </b-tabs>
        </b-card-code>

        <b-card-code>
            <b-row>
                <b-col v-if="!processing" md="2" lg="2" offset-lg="10" offset-xl="10">
                    <b-button variant="primary" block @click="saveProfileDetails">Guardar</b-button>
                </b-col>

                <b-col v-if="processing" md="2" lg="2" offset-lg="10" offset-xl="10">
                    <b-button variant="primary" disabled block><b-spinner small label="Cargando..." /></b-button>
                </b-col>
            </b-row>                   
        </b-card-code>
    </div>
  
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BFormInput, BRow, BCol, BFormGroup, BFormDatepicker, BFormTextarea, BFormFile, BButton, BSpinner, BFormSelect, BCardText,
} from 'bootstrap-vue'
import departamentos from '../../assets/departamentos-municipios.json'

export default {
  components: {
    BCardCode,
    BTabs, 
    BTab, 
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormDatepicker,
    BFormTextarea,   
    BFormFile,
    BButton,
    BSpinner,
    BFormSelect,
    BCardText,
  },
  data() {
    return {
        processing: false,
        producerID: null,
        profileDetails: {},
        form: {
            cardNumber: null,
            date: null,
            phone: null,
        },
        options: {
            date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
            },
            number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            },
        },  
        genderOptions: [
            { value: 'Masculino', text: 'Masculino' },
            { value: 'Femenino', text: 'Femenino' },
        ],   
        documentTypeOptions: [
            { value: 'Cédula', text: 'Cédula' },
            { value: 'Cédula de Extranjería', text: 'Cédula de Extranjería' },
            { value: 'Pasaporte Nacional', text: 'Pasaporte Nacional' },
            { value: 'Pasaporte Extranjero', text: 'Pasaporte Extranjero' },
        ],  
        accountTypeOptions: [
            { value: 'Ahorros', text: 'Ahorros' },
            { value: 'Corriente', text: 'Corriente' },
        ], 
        documentFile: null,
        bankAccountCertificationFile: null,
        producerFormatFile: null,
        declaracionDeRentaFile: null,
        rutFile: null,
        departamentosList: departamentos,
        municipiosList: [],
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.producerID != null) {
      this.producerID = this.$route.query.producerID;
    } else {
      var userData = getUserData();
      this.producerID = userData.userID;
    }
    
    this.getProfileDetails();
  },
  watch:{
      documentFile: function(val) { this.documentFile = val; this.uploadFile(val, 'documentFile'); },
      bankAccountCertificationFile: function(val) { this.bankAccountCertificationFile = val; this.uploadFile(val, 'bankAccountCertificationFile'); },
      producerFormatFile: function(val) { this.producerFormatFile = val; this.uploadFile(val, 'producerFormatFile'); },
      declaracionDeRentaFile: function(val) { this.declaracionDeRentaFile = val; this.uploadFile(val, 'declaracionDeRentaFile'); },
      rutFile: function(val) { this.rutFile = val; this.uploadFile(val, 'rutFile'); },
  },
  methods: {
    getProfileDetails() {
        this.processing = true;        
        axios.get('/User/GetProfileDetails', { params: { userID: this.producerID, userType: 'Productor' }})
        .then(response => {
           this.processing = false;
           this.profileDetails = response.data;
           if(this.profileDetails.city){
               this.municipiosList.push(this.profileDetails.city);
           }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    saveProfileDetails() {
        this.processing = true;
        var payload = {
            profileType: "Productor",
            profileDetails: this.profileDetails
        }
        axios.post('/User/SaveProfileDetails', payload )
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.$bvToast.toast("Información guardada de forma exitosa", { title: 'Ok', variant: 'success', solid: true, })     
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    uploadFile(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();
        formData.append('file', fileToUpload);
        axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
            this.saveProfileDetails();   
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    setFileUrl(fileType, fileUrl) {
        switch(fileType) {
            case 'documentFile':
                this.profileDetails.documentUrl = fileUrl;
                break;
            case 'bankAccountCertificationFile':
                this.profileDetails.bankAccountCertificationUrl = fileUrl;
                break;
            case 'producerFormatFile':
                this.profileDetails.producerFormatUrl = fileUrl;
                break;
            case 'declaracionDeRentaFile':
                this.profileDetails.declaracionDeRentaUrl = fileUrl;
                break;
            case 'rutFile':
                this.profileDetails.rutUrl = fileUrl;
                break;
            case '':
                // code block
                break;
        }
    },
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },
    onDepartamentoSelect(event){
        this.locationState = event;        
        var departamento = this.departamentosList.find(x => x.departamento === event);
        this.municipiosList = departamento.ciudades;
    }
  },
  
}
</script>

<style lang="scss">
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>